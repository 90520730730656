<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>夺宝</template>
    </breadcrumb-nav>
    <el-card>
      <el-tabs>
        <el-tab-pane label="夺宝商品">
          <hunt-goods-pane></hunt-goods-pane>
        </el-tab-pane>
        <el-tab-pane label="中奖记录">
          <hunt-record-pane></hunt-record-pane>
        </el-tab-pane>
        <el-tab-pane label="规则">
          <hunt-rule-pane></hunt-rule-pane>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import HuntRecordPane from './huntChildComponents/HuntRecordPane'
import HuntGoodsPane from './huntChildComponents/HuntGoodsPane'
import HuntRulePane from './huntChildComponents/HuntRulePane'

export default {
  name: 'Hunt',
  components: {
    HuntRecordPane,
    HuntGoodsPane,
    HuntRulePane,
    BreadcrumbNav
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
