<template>
  <div>
    <el-card style="margin-bottom: 10px" shadow="never">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="edit">修改</el-button>
    </el-card>
    <el-form ref="form" :model="form" label-width="150px" :disabled="disabled">
      <el-form-item label="活动期限">
        <el-input v-model="form.dateDesc"></el-input>
      </el-form-item>
      <el-form-item label="奖励过期时间">
        <el-input type="Number" v-model.number="form.expireTime"></el-input>
      </el-form-item>
      <el-form-item label="发货时间">
        <el-input type="Number" v-model.number="form.resetInterval"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { GetActivityRequest, UpdateActivityRequest } from '../../../network/lottery'

export default {
  name: 'HuntRulePane',
  data () {
    return {
      form: {
        resetInterval: 0,
        expireTime: 0,
        dateDesc: ''
      },
      disabled: true
    }
  },
  mounted () {
    // 获取列表数据方法
    this.getHuntActivity()
  },
  methods: {
    getHuntActivity () {
      GetActivityRequest(3).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取规则失败', 'error')
        }
        console.log('activity res:', res.data)
        this.form = res.data.data
      })
    },
    save () {
      this.disabled = true
      const rule = {
        resetInterval: this.form.resetInterval,
        expireTime: this.form.expireTime,
        dateDesc: this.form.dateDesc,
        name: '夺宝',
        desc: '夺宝',
        activityType: 3
      }
      UpdateActivityRequest(rule).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改规则失败', 'error')
        }
        this.alertMessage('修改规则成功', 'success')
      })
    },
    edit () {
      this.disabled = false
    }
  }
}
</script>

<style scoped>

</style>
