<template>
  <div>
  <el-table style="width: 100%" border :data="list">
    <el-table-column type="index" label="序号" width="80px" align="center">
    </el-table-column>
    <el-table-column prop="goods.imageUrl" label="商品图标" width="80px">
      <template slot-scope="{ row }">
        <img :src="row.goods.imageUrl" alt="" style="width: 50px; height: 50px" />
      </template>
    </el-table-column>
    <el-table-column prop="goods.name" label="商品名称" width="200px">
    </el-table-column>
    <el-table-column prop="hunt.skuDesc" label="规格信息">
    </el-table-column>
    <el-table-column prop="hunt.skuPrice" label="价格">
    </el-table-column>
    <el-table-column prop="hunt.joined" label="累计人数">
    </el-table-column>
    <el-table-column prop="hunt.origin" label="初始人数">
    </el-table-column>
    <el-table-column prop="hunt.reached" label="开奖人数">
    </el-table-column>
    <el-table-column prop="hunt.cost" label="夺宝金币">
    </el-table-column>
    <el-table-column prop="hunt.delay" label="延迟开奖">
    </el-table-column>
    <el-table-column prop="hunt.period" label="期数">
    </el-table-column>
    <!--<el-table-column prop="hunt.delay" label="发放时间" width="120px">
    </el-table-column>-->
    <el-table-column prop="status" width="200" label="状态">
      <template slot-scope="scope">
        <p>是否参与夺宝：
          <el-switch v-model="scope.row.hunt.state" :active-value=1
                     :inactive-value=0 @change="changeGoodsHuntConfigState(scope.row)">
          </el-switch>
        </p>
        <p>启动夺宝：
          <el-switch v-model="scope.row.hunt.huntState" :active-value=1
                     :inactive-value=0 @change="changeGoodsHuntState(scope.row)">
          </el-switch>
        </p>
      </template>
    </el-table-column>
    <el-table-column prop="prop" label="操作" width="100px">
      <template slot-scope="{row}">
        <el-row>
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            @click="updateHuntConfig(row)"
          >配置</el-button>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="listQuery.page"
    :page-sizes="[5, 10]"
    :page-size="listQuery.size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total">
  </el-pagination>
  </div>
</template>

<script>
import { listHuntConfigRequest, updateHuntConfigStateRequest, updateHuntStateRequest } from '../../../network/hunt'

export default {
  name: 'HuntGoodsPane',
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      listQuery: {
        state: 2,
        huntState: 2,
        runState: 2,
        page: 1,
        size: 10
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getHuntConfigList()
  },
  methods: {
    getHuntConfigList () {
      listHuntConfigRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取规则列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          if (item.goods.icon.includes('http')) {
            item.goods.imageUrl = item.goods.icon
          } else {
            item.goods.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.goods.icon
          }
        }
        console.log('hunt goods:', this.list)
        this.total = result.data.total
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getHuntConfigList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getHuntConfigList()
    },
    changeGoodsHuntConfigState (item) {
      console.log('config state item:', item)
      updateHuntConfigStateRequest({
        id: item.hunt.id,
        state: item.hunt.state
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改商品是否开启夺宝状态失败', 'error')
        }
        this.alertMessage('修改商品是否开启夺宝状态成功', 'success')
        this.getHuntConfigList()
      })
    },
    changeGoodsHuntState (item) {
      if (item.hunt.huntState === 1 && (item.hunt.skuId === 0 || item.hunt.reached === 0 || item.hunt.cost === 0)) {
        item.hunt.huntState = 0
        this.alertMessage('启动夺宝前请先设置规格、开奖人数、消耗金币', 'warning')
        return
      }
      updateHuntStateRequest({
        id: item.hunt.id,
        huntState: item.hunt.huntState
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改夺宝启动状态失败', 'error')
        }
        this.alertMessage('修改夺宝启动状态成功', 'success')
        this.getHuntConfigList()
      })
    },
    updateHuntConfig (row) {
      this.$router.push({ path: '/huntConfig', query: { id: row.hunt.id } })
    }
  }
}
</script>

<style scoped>

</style>
